import { isNullOrEmpty } from "./utils/AppUtil";

export const ENV_STAGE = "stage";
export const ENV_DEV = "dev";
export const ENV_UAT = "uat";
export const ENV_PROD = "prod";
export const ENV_PROD_BLUE = "prod_blue";
export const ENV_LOCAL = "local";

//backend environment
export const env = ENV_PROD;

let backendUrlMap = {};
backendUrlMap[ENV_PROD] = process.env.REACT_APP_BACKEND_URL_PROD;
backendUrlMap[ENV_STAGE] = process.env.REACT_APP_BACKEND_URL_STAGE;
backendUrlMap[ENV_UAT] = process.env.REACT_APP_BACKEND_URL_UAT;
backendUrlMap[ENV_DEV] = process.env.REACT_APP_BACKEND_URL_DEV;
backendUrlMap[ENV_PROD_BLUE] = process.env.REACT_APP_BACKEND_URL_PROD_BLUE;

let backendUrlMapAnalytics = {};
backendUrlMapAnalytics[ENV_PROD] = process.env.REACT_APP_BACKEND_URL_ANALYTICS_PROD;
backendUrlMapAnalytics[ENV_STAGE] = process.env.REACT_APP_BACKEND_URL_ANALYTICS_STAGE;
backendUrlMapAnalytics[ENV_UAT] = process.env.REACT_APP_BACKEND_URL_ANALYTICS_UAT;
backendUrlMapAnalytics[ENV_DEV] = process.env.REACT_APP_BACKEND_URL_ANALYTICS_DEV;
backendUrlMapAnalytics[ENV_PROD_BLUE] = process.env.REACT_APP_BACKEND_URL_ANALYTICS_PROD_BLUE;

let frontendUrlMap = {};
frontendUrlMap[ENV_PROD] = process.env.REACT_APP_FRONTEND_URL_PROD;
frontendUrlMap[ENV_STAGE] = process.env.REACT_APP_FRONTEND_URL_STAGE;
frontendUrlMap[ENV_UAT] = process.env.REACT_APP_FRONTEND_URL_UAT;
frontendUrlMap[ENV_DEV] = process.env.REACT_APP_FRONTEND_URL_DEV;
frontendUrlMap[ENV_PROD_BLUE] = process.env.REACT_APP_FRONTEND_URL_PROD_BLUE;
frontendUrlMap[ENV_LOCAL] = process.env.REACT_APP_FRONTEND_URL_LOCAL;

let projectClientIdMap = {};
projectClientIdMap[ENV_PROD] = process.env.REACT_APP_PROD_PROJECT_CLIENT_ID;
projectClientIdMap[ENV_STAGE] = process.env.REACT_APP_STAGE_PROJECT_CLIENT_ID;
projectClientIdMap[ENV_UAT] = process.env.REACT_APP_UAT_PROJECT_CLIENT_ID;
projectClientIdMap[ENV_DEV] = process.env.REACT_APP_DEV_PROJECT_CLIENT_ID;
projectClientIdMap[ENV_PROD_BLUE] = process.env.REACT_APP_PROD_BLUE_CLIENT_ID;

export const frontendUrl = frontendUrlMap[env];

export const projectClientId = projectClientIdMap[env];

export const controllerUrl = backendUrlMap[env];
export const analyticsControllerUrl = backendUrlMapAnalytics[env];

// Api end points

//analytics
export const URL_ANALYTICS = analyticsControllerUrl + '/bank-data-fe/fe_logger_handler';

//choice
export const URL_SUBMIT_CHOICE = controllerUrl + '/submit_choice';

//netbanking
export const URL_VERIFY_INSTITUTION_CUSTOMER = controllerUrl + '/verifyinstitutioncustomer';

//statementupload
export const URL_COMPLETE_UPLOAD = controllerUrl + '/completeuiupload';
export const URL_UPLOAD_STMT = controllerUrl + '/uploaduistmt';
export const URL_DELETE_STMT = controllerUrl + '/deleteuistmt';
export const URL_STATUS_CHECK = controllerUrl + '/statuscheckuistmt';

//emailstatement
export const URL_EMAIL_SUBMIT = controllerUrl + '/emailsubmit';

//account aggregator
export const URL_GET_AA_LIST = controllerUrl + '/get_aa_list';
export const URL_INITIATE_AA_CONSENT = controllerUrl + '/initiate_aa_consent';
export const URL_CHECK_FP_STATUS = controllerUrl + '/check_fip_status';
export const URL_DECRYPT_AA_REDIRECTION = controllerUrl + '/decrypt-aa-webredirection';

//common
export const URL_INSTITUTION_LIST = controllerUrl + '/institution_list';
export const URL_USER_ACTION = controllerUrl + '/useraction';
export const URL_CANCEL_MFA = controllerUrl + '/cancelmfa';
export const URL_TXN_COMPLETE = controllerUrl + '/txncomplete';
export const URL_VIEW_REPORT = controllerUrl + '/retrievereportinternal';


export const userActionBtnStyleText = 'text';
export const userActionBtnStyleButtons = 'buttons';

export const homeInputStyleIcon = 'icon';
export const homeInputStyleNoIcon = 'no_icon';

export const loaderStyleDots = 'dots';
export const loaderStyleCircle = 'circle';

export const otpStyleDigits = 'digits';
export const otpStyleBox = 'box';

export const otpTimerStyleSeconds = 'seconds';
export const otpTimerStyleClock = 'clock';

export const multiAccountStyle1 = 'style1';
export const multiAccountStyle2 = 'style2';

let themes = {
    "dt": {
        "colors": {
            "screen_bg_color": "#ffffff",
            "screen_primary_text_color": "#333333",
            "screen_secondary_text_color": "#191919",
            "primary_btn_bg_color": "#52a3c5",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#52a3c5",
            "loader_color": "#52a3c5",
            "loader_color1": "#52a3c5",
            "loader_color2": "#52a3c51A",
            "info_title_text_color": "#52a3c5",
            "lock_icon_bg_color": "#f5f5fa",
            "input_field_bg_color": "#ffffff",
            "input_field_border_color": "#d3d8de",
            "input_field_text_color": "#22202e",
            "div_bg_color": "#f9f9f9" //#ededed
        },
        "styles": {
            "loader_style": loaderStyleDots,
            "home_input_style": homeInputStyleIcon,
            "user_action_mob_btn_style": userActionBtnStyleButtons,
            "otp_style": otpStyleBox,
            "otp_timer_style": otpTimerStyleSeconds,
            "multi_account_style": multiAccountStyle1,
            "btn_height": '48px',
            "btn_font_size": '16px',
            "input_field_height": '42px',
            "input_field_font_size": '14px',
            "logo_height": '34px'
        },
        "strings": {
            "title": "Digitap",
            "netbanking_home_info_title": "Your Credentials Are Never Stored!",
            "netbanking_home_info_desc": "To give you the best possible, we need to analyse your transaction for the last 6 months. Once analysed we delete your details",
            "home_primary_btn_text": "Next",
            "user_action_captcha_title": "CAPTCHA Verification",
            "user_action_captcha_desc": "Please enter image text below for security check.",
            "user_action_otp_title": "User Action required!",
            "user_action_otp_desc": "Please enter OTP received on mobile/e-mail from {bank}",
            "user_action_question_title": "User Action required!",
            "user_action_question_desc": "wants you to answer this security question:",
            "user_action_expression_title": "Verification",
            "user_action_expression_desc": "Please validate the following expression.",
            "user_action_account_title": "User Action required!",
            "user_action_account_desc": "Choose one of the accounts",
            "processing_title": "Processing",
            "processing_desc": "Your request is in progress. Please do not close or refresh the page.",
            "error_primary_btn_text": "Try again",
            "error_secondary_btn_text": "Take me Back",
            "aa_error_secondary_btn_text": "Take me Back",
            "tc_text": "By clicking Next you agree to our "
        },
        "redirections": {
            "aa_redirection": "false",
            "nb_redirection": "false"
        },
        "font": "roboto",
        "others": {
            "powered_by_required": "true",
            "header_required": "true",
            "logo_required": "true",
            "tc_required": "true",
            "user_action_icons_required": "false",
            "analytics_callback_required": "false"
        }
    },
    "kb": {
        "colors": {
            "primary_btn_bg_color": "#fdd535",
            "primary_btn_text_color": "#000000",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#fdd535",
            "loader_color1": "#fdd535",
            "info_title_text_color": "#f2b100",
        },
        "strings": {
            "title": "KreditBee",
        },
        "font": "montserrat",
        "others": {
            "tc_required": "false"
        }
    },
    "kz": {
        "colors": {
            "primary_btn_bg_color": "#4747b4",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#4747b4",
            "loader_color1": "#4747b4",
            "info_title_text_color": "#4747b4",
        },
        "strings": {
            "title": "Kreditzy",
        },
        "font": "poppins"
    },
    "af": {
        "colors": {
            "primary_btn_bg_color": "#2DCB94",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#2DCB94",
            "loader_color1": "#2DCB94",
            "info_title_text_color": "#2DCB94",
        },
        "strings": {
            "title": "Avail-Finance",
        },
        "font": "roboto"
    },
    "nv": {
        "colors": {
            "primary_btn_bg_color": "#FF3333",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#F5F5F5",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#F5F5F5",
            "loader_color1": "#FF3333",
            "info_title_text_color": "#000000",
        },
        "strings": {
            "title": "Navi",
        },
        "font": "navisans"
    },
    "gq": {
        "colors": {
            "primary_btn_bg_color": "#0057FB",
            "primary_btn_text_color": "#F7FAFF",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#0057FB",
            "loader_color1": "#0057FB",
            "info_title_text_color": "#0057FB",
        },
        "strings": {
            "title": "GrayQuest",
        },
        "font": "inter"
    },
    "glib": {
        "colors": {
            "primary_btn_bg_color": "#0d6efd",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#0d6efd",
            "loader_color1": "#0d6efd",
            "info_title_text_color": "#0d6efd",
        },
        "strings": {
            "title": "Glib",
        },
        "font": "roboto"
    },
    "fin": {
        "colors": {
            "primary_btn_bg_color": "#D22F57",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#D22F57",
            "loader_color1": "#D22F57",
            "info_title_text_color": "#D22F57",
        },
        "strings": {
            "title": "Finnable",
        },
        "font": "opensans"
    },
    "am": {
        "colors": {
            "primary_btn_bg_color": "#4749EF",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#4749EF",
            "loader_color1": "#4749EF",
            "info_title_text_color": "#4749EF",
        },
        "strings": {
            "title": "Arthmate",
        },
        "font": "poppins"
    },
    "mlc": {
        "colors": {
            "primary_btn_bg_color": "#0FA82E",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#0FA82E",
            "loader_color1": "#0FA82E",
            "info_title_text_color": "#0FA82E",
        },
        "strings": {
            "title": "MyLoanCare",
        },
        "font": "poppins"
    },
    "kbnbfc": {
        "colors": {
            "primary_btn_bg_color": "#238ecb",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#238ecb",
            "loader_color1": "#238ecb",
            "info_title_text_color": "#238ecb",
        },
        "strings": {
            "title": "KBNBFC",
            "aa_error_secondary_btn_text": "Re-try"
        },
        "redirections": {
            "aa_redirection": "true",
        },
        "font": "montserrat",
    },
    "hdbfs": {
        "colors": {
            "primary_btn_bg_color": "#10498B",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#10498B",
            "loader_color1": "#10498B",
            "info_title_text_color": "#10498B",
        },
        "strings": {
            "title": "HDBFS",
        },
        "font": "helvetica"
    },
    "deci": {
        "colors": {
            "primary_btn_bg_color": "#52a3c5",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#52a3c5",
            "loader_color1": "#52a3c5",
            "info_title_text_color": "#52a3c5",
        },
        "strings": {
            "title": "Decimus",
        },
        "redirections": {
            "aa_redirection": "true",
        },
        "font": "roboto"
    },
    "idfy": {
        "colors": {
            "primary_btn_bg_color": "#2642B3",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#2642B3",
            "loader_color1": "#2642B3",
            "info_title_text_color": "#2642B3",
        },
        "strings": {
            "title": "Idfy",
        },
        "font": "helvetica",
        "others": {
            "powered_by_required": "false"
        }
    },
    "mh": {
        "colors": {
            "primary_btn_bg_color": "#E31B37",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#E31B37",
            "loader_color1": "#E31B37",
            "info_title_text_color": "#E31B37",
        },
        "strings": {
            "title": "Mahindra",
        },
        "font": "lato"
    },
    "sf": {
        "colors": {
            "primary_btn_bg_color": "#EE0025",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#EE0025",
            "loader_color1": "#EE0025",
            "info_title_text_color": "#EE0025",
        },
        "strings": {
            "title": "Stashfin",
        },
        "font": "roboto"
    },
    "jar": {
        "colors": {
            "screen_bg_color": "#272239",
            "screen_primary_text_color": "#ffffff",
            "screen_secondary_text_color": "#D5CDF2",
            "primary_btn_bg_color": "#6637E4",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#6637E4",
            "loader_color1": "#776E94",
            "loader_color2": "#EEEAFF",
            "lock_icon_bg_color": "#432d83",
            "info_title_text_color": "#FFFFFF",
            "input_field_bg_color": "#272239",
            "input_field_border_color": "#776E94",
            "input_field_text_color": "#FFFFFF",
            "div_bg_color": "#2E2942"
        },
        "styles": {
            "loader_style": loaderStyleCircle,
            "home_input_style": homeInputStyleNoIcon,
            "user_action_mob_btn_style": userActionBtnStyleText,
            "otp_style": otpStyleDigits,
            "otp_timer_style": otpTimerStyleClock,
            "multi_account_style": multiAccountStyle2,
            "btn_height": '56px',
            "btn_font_size": '14px',
            "input_field_height": '48px',
            "input_field_font_size": '14px'
        },
        "strings": {
            "title": "Jar",
            "netbanking_home_info_title": "We do not store your bank details!",
            "netbanking_home_info_desc": "We will check your last 6 months of transactions to find the best offer for you.",
            "home_primary_btn_text": "Continue",
            "user_action_captcha_title": "Captcha Verification",
            "user_action_captcha_desc": "Enter text from the image shown below for security check",
            "user_action_otp_title": "Verify OTP",
            "user_action_otp_desc": "Enter the OTP sent by your {bank} to registered mobile number",
            "user_action_question_title": "Security Question",
            "user_action_question_desc": "wants you to answer this security question.",
            "user_action_expression_title": "Captcha Verification",
            "user_action_expression_desc": "Please answer the below question to proceed",
            "user_action_account_title": "Select a bank account",
            "user_action_account_desc": "Choose one of the accounts to proceed",
            "processing_title": "Processing...",
            "processing_desc": "Your request is in progress. Please do not minimise or close the app",
            "error_primary_btn_text": "Try again",
            "error_secondary_btn_text": "Try other methods",
            "tc_text": "By proceeding you agree to our "
        },
        "redirections": {
            "nb_redirection": "true"
        },
        "font": "inter",
        "others": {
            //"powered_by_required": "false",
            "header_required": "false",
            "user_action_icons_required": "true",
            "analytics_callback_required": "true"
        }
    },
    "tc": {
        "strings": {
            "title": "TallyConnect",
        },
        "others": {
            "header_required": "false",
            "powered_by_required": "false"
        }
    },
    "cp": {
        "colors": {
            "primary_btn_bg_color": "#1987fb",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#1987fb",
            "loader_color1": "#1987fb",
            "info_title_text_color": "#1987fb",
        },
        "strings": {
            "title": "CirclePe",
        },
        "font": "dmsans"
    },
    "ig": {
        "strings": {
            "title": "Ignosis",
        },
        "others": {
            "powered_by_required": "false"
        }
    },
    "ig_no": {
        "strings": {
            "title": "Ignosis",
        },
        "others": {
            "logo_required": "false",
            "powered_by_required": "false"
        }
    },
    "tb": {
        "colors": {
            "primary_btn_bg_color": "#66bb94",
            "primary_btn_text_color": "#ffffff",
            "secondary_btn_bg_color": "#ffffff",
            "secondary_btn_text_color": "#000000",
            "secondary_btn_border_color": "#66bb94",
            "loader_color1": "#66bb94",
            "info_title_text_color": "#66bb94",
        },
        "strings": {
            "title": "TransBnk",
        },
        "styles": {
            "logo_height": '45px'
        },
        "font": "inter",
        "others": {
            "powered_by_required": "false"
        }
    },
    "ff": {
        "strings": {
            "title": "FinFresh",
        },
        "styles": {
            "logo_height": '45px'
        },
    }
}

let THEME_ID_DT = "dt";
//themeId
export const getThemeId = () => {
    let themeId = sessionStorage.getItem("theme_id");
    if (isNullOrEmpty(themeId) || !(themeId in themes)) {
        return THEME_ID_DT;
    }
    else {
        return themeId;
    }
}

//colors
const getColors = () => {
    return (!isNullOrEmpty(themes[getThemeId()].colors)) ? themes[getThemeId()].colors : themes[THEME_ID_DT].colors;
}

export const getScreenBgColor = () => {
    return (!isNullOrEmpty(getColors().screen_bg_color)) ? getColors().screen_bg_color : themes[THEME_ID_DT].colors.screen_bg_color;
}

export const getScreenPrimaryTextColor = () => {
    return (!isNullOrEmpty(getColors().screen_primary_text_color)) ? getColors().screen_primary_text_color : themes[THEME_ID_DT].colors.screen_primary_text_color;
}

export const getScreenSecondaryTextColor = () => {
    return (!isNullOrEmpty(getColors().screen_secondary_text_color)) ? getColors().screen_secondary_text_color : themes[THEME_ID_DT].colors.screen_secondary_text_color;
}

export const getPrimaryBtnBgColor = () => {
    return getColors().primary_btn_bg_color;
}

export const getPrimaryBtnTextColor = () => {
    return getColors().primary_btn_text_color;
}

export const getSecondaryBtnBgColor = () => {
    return getColors().secondary_btn_bg_color;
}

export const getSecondaryBtnTextColor = () => {
    return getColors().secondary_btn_text_color;
}

export const getSecondaryBtnBorderColor = () => {
    return getColors().secondary_btn_border_color;
}

export const getLoaderColor1 = () => {
    return (!isNullOrEmpty(getColors().loader_color1)) ? getColors().loader_color1 : themes[THEME_ID_DT].colors.loader_color;
}

export const getLoaderColor2 = () => {
    return (!isNullOrEmpty(getColors().loader_color2)) ? getColors().loader_color2 : themes[THEME_ID_DT].colors.loader_color;
}

export const getDivBgColor = () => {
    return (!isNullOrEmpty(getColors().div_bg_color)) ? getColors().div_bg_color : themes[THEME_ID_DT].colors.div_bg_color;
}

export const getInputFieldBgColor = () => {
    return (!isNullOrEmpty(getColors().input_field_bg_color)) ? getColors().input_field_bg_color : themes[THEME_ID_DT].colors.input_field_bg_color;
}

export const getInputFieldBorderColor = () => {
    return (!isNullOrEmpty(getColors().input_field_border_color)) ? getColors().input_field_border_color : themes[THEME_ID_DT].colors.input_field_border_color;
}

export const getInputFieldTextColor = () => {
    return (!isNullOrEmpty(getColors().input_field_text_color)) ? getColors().input_field_text_color : themes[THEME_ID_DT].colors.input_field_text_color;
}

export const getLockIconBgColor = () => {
    return (!isNullOrEmpty(getColors().lock_icon_bg_color)) ? getColors().lock_icon_bg_color : themes[THEME_ID_DT].colors.lock_icon_bg_color
}

export const getInfoTitleTextColor = () => {
    return getColors().info_title_text_color;
}

// styles

const getStyles = () => {
    return (!isNullOrEmpty(themes[getThemeId()].styles)) ? themes[getThemeId()].styles : themes[THEME_ID_DT].styles;
}

export const getLoaderStyle = () => {
    return (!isNullOrEmpty(getStyles().loader_style)) ? getStyles().loader_style : themes[THEME_ID_DT].styles.loader_style
}

export const getUserActionBtnStyle = () => {
    return (!isNullOrEmpty(getStyles().user_action_mob_btn_style)) ? getStyles().user_action_mob_btn_style : themes[THEME_ID_DT].styles.user_action_mob_btn_style
}

export const getHomeInputStyle = () => {
    return (!isNullOrEmpty(getStyles().home_input_style)) ? getStyles().home_input_style : themes[THEME_ID_DT].styles.home_input_style;
}

export const getOtpStyle = () => {
    return (!isNullOrEmpty(getStyles().otp_style)) ? getStyles().otp_style : themes[THEME_ID_DT].styles.otp_style
}

export const getOtpTimerStyle = () => {
    return (!isNullOrEmpty(getStyles().otp_timer_style)) ? getStyles().otp_timer_style : themes[THEME_ID_DT].styles.otp_timer_style
}

export const getMultiAccountStyle = () => {
    return (!isNullOrEmpty(getStyles().multi_account_style)) ? getStyles().multi_account_style : themes[THEME_ID_DT].styles.multi_account_style
}

export const getBtnHeight = () => {
    return (!isNullOrEmpty(getStyles().btn_height)) ? getStyles().btn_height : themes[THEME_ID_DT].styles.btn_height;
}

export const getInputFieldHeight = () => {
    return (!isNullOrEmpty(getStyles().input_field_height)) ? getStyles().input_field_height : themes[THEME_ID_DT].styles.input_field_height;
}

export const getInputFieldFontSize = () => {
    return (!isNullOrEmpty(getStyles().input_field_font_size)) ? getStyles().input_field_font_size : themes[THEME_ID_DT].styles.input_field_font_size;
}

export const getBtnFontSize = () => {
    return (!isNullOrEmpty(getStyles().btn_font_size)) ? getStyles().btn_font_size : themes[THEME_ID_DT].styles.btn_font_size;
}

export const getLogoHeight = () => {
    return (!isNullOrEmpty(getStyles().logo_height)) ? getStyles().logo_height : themes[THEME_ID_DT].styles.logo_height;
}

// strings

const getStrings = () => {
    return (!isNullOrEmpty(themes[getThemeId()].strings)) ? themes[getThemeId()].strings : themes[THEME_ID_DT].strings;
}

export const getTitle = () => {
    return getStrings().title;
}

export const getUserActionCaptchaTitle = () => {
    return (!isNullOrEmpty(getStrings().user_action_captcha_title)) ? getStrings().user_action_captcha_title : themes[THEME_ID_DT].strings.user_action_captcha_title;
}

export const getUserActionCaptchaDesc = () => {
    return (!isNullOrEmpty(getStrings().user_action_captcha_desc)) ? getStrings().user_action_captcha_desc : themes[THEME_ID_DT].strings.user_action_captcha_desc;
}

export const getUserActionOtpTitle = () => {
    return (!isNullOrEmpty(getStrings().user_action_otp_title)) ? getStrings().user_action_otp_title : themes[THEME_ID_DT].strings.user_action_otp_title;
}

export const getUserActionOtpDesc = () => {
    return (!isNullOrEmpty(getStrings().user_action_otp_desc)) ? getStrings().user_action_otp_desc : themes[THEME_ID_DT].strings.user_action_otp_desc;
}

export const getUserActionQuestionTitle = () => {
    return (!isNullOrEmpty(getStrings().user_action_question_title)) ? getStrings().user_action_question_title : themes[THEME_ID_DT].strings.user_action_question_title;
}

export const getUserActionQuestionDesc = () => {
    return (!isNullOrEmpty(getStrings().user_action_question_desc)) ? getStrings().user_action_question_desc : themes[THEME_ID_DT].strings.user_action_question_desc;
}

export const getUserActionExpressionTitle = () => {
    return (!isNullOrEmpty(getStrings().user_action_expression_title)) ? getStrings().user_action_expression_title : themes[THEME_ID_DT].strings.user_action_expression_title;
}

export const getUserActionExpressionDesc = () => {
    return (!isNullOrEmpty(getStrings().user_action_expression_desc)) ? getStrings().user_action_expression_desc : themes[THEME_ID_DT].strings.user_action_expression_desc;
}

export const getUserActionAccountTitle = () => {
    return (!isNullOrEmpty(getStrings().user_action_account_title)) ? getStrings().user_action_account_title : themes[THEME_ID_DT].strings.user_action_account_title;
}

export const getUserActionAccountnDesc = () => {
    return (!isNullOrEmpty(getStrings().user_action_account_desc)) ? getStrings().user_action_account_desc : themes[THEME_ID_DT].strings.user_action_account_desc;
}

export const getAAErrorSecondaryBtnText = () => {
    return (!isNullOrEmpty(getStrings().aa_error_secondary_btn_text)) ? getStrings().aa_error_secondary_btn_text : themes[THEME_ID_DT].strings.aa_error_secondary_btn_text;
}

export const getErrorPrimaryBtnText = () => {
    return (!isNullOrEmpty(getStrings().error_primary_btn_text)) ? getStrings().error_primary_btn_text : themes[THEME_ID_DT].strings.error_primary_btn_text;
}

export const getErrorSecondaryBtnText = () => {
    return (!isNullOrEmpty(getStrings().error_secondary_btn_text)) ? getStrings().error_secondary_btn_text : themes[THEME_ID_DT].strings.error_secondary_btn_text;
}

export const getNetbankingHomeInfoTitleText = () => {
    return (!isNullOrEmpty(getStrings().netbanking_home_info_title)) ? getStrings().netbanking_home_info_title : themes[THEME_ID_DT].strings.netbanking_home_info_title;
}

export const getNetbankingHomeInfoDescText = () => {
    return (!isNullOrEmpty(getStrings().netbanking_home_info_desc)) ? getStrings().netbanking_home_info_desc : themes[THEME_ID_DT].strings.netbanking_home_info_desc;
}

export const getHomePrimaryBtnText = () => {
    return (!isNullOrEmpty(getStrings().home_primary_btn_text)) ? getStrings().home_primary_btn_text : themes[THEME_ID_DT].strings.home_primary_btn_text;
}

export const getProcessingTitle = () => {
    return (!isNullOrEmpty(getStrings().processing_title)) ? getStrings().processing_title : themes[THEME_ID_DT].strings.processing_title;
}

export const getProcessingDesc = () => {
    return (!isNullOrEmpty(getStrings().processing_desc)) ? getStrings().processing_desc : themes[THEME_ID_DT].strings.processing_desc;
}

export const getTCText = () => {
    return (!isNullOrEmpty(getStrings().tc_text)) ? getStrings().tc_text : themes[THEME_ID_DT].strings.tc_text;
}

//redirections

const getRedirections = () => {
    return (!isNullOrEmpty(themes[getThemeId()].redirections)) ? themes[getThemeId()].redirections : themes[THEME_ID_DT].redirections;
}

export const getAARedirection = () => {
    return (!isNullOrEmpty(getRedirections().aa_redirection)) ? getRedirections().aa_redirection : themes[THEME_ID_DT].redirections.aa_redirection;
}

export const getNBRedirection = () => {
    return (!isNullOrEmpty(getRedirections().nb_redirection)) ? getRedirections().nb_redirection : themes[THEME_ID_DT].redirections.nb_redirection;
}

//others

const getOthers = () => {
    return (!isNullOrEmpty(themes[getThemeId()].others)) ? themes[getThemeId()].others : themes[THEME_ID_DT].others;
}

export const getPoweredByRequired = () => {
    return (!isNullOrEmpty(getOthers().powered_by_required)) ? getOthers().powered_by_required : themes[THEME_ID_DT].others.powered_by_required;
}

export const getHeaderRequired = () => {
    return (!isNullOrEmpty(getOthers().header_required)) ? getOthers().header_required : themes[THEME_ID_DT].others.header_required;
}

export const getLogoRequired = () => {
    return (!isNullOrEmpty(getOthers().logo_required)) ? getOthers().logo_required : themes[THEME_ID_DT].others.logo_required;
}

export const getTCRequired = () => {
    return (!isNullOrEmpty(getOthers().tc_required)) ? getOthers().tc_required : themes[THEME_ID_DT].others.tc_required;
}

export const getUserActionIconsRequired = () => {
    return (!isNullOrEmpty(getOthers().user_action_icons_required)) ? getOthers().user_action_icons_required : themes[THEME_ID_DT].others.user_action_icons_required;
}

export const getAnalyticsCallbackRequired = () => {
    return (!isNullOrEmpty(getOthers().analytics_callback_required)) ? getOthers().analytics_callback_required : themes[THEME_ID_DT].others.analytics_callback_required;
}

//fonts

const getFont = () => {
    return (!isNullOrEmpty(themes[getThemeId()].font)) ? themes[getThemeId()].font : themes[THEME_ID_DT].font;
}

export const getFontRegular = () => {
    return getFont() + "regular";
}

export const getFontMedium = () => {
    return getFont() + "medium";
}

export const getFontBold = () => {
    return getFont() + "bold";
}

//images
//images vary based on the theme
const getImagesPath = () => {
    return './static/images/' + getThemeId();
}

//Digitap theme images path
const getDefaultImagesPath = () => {
    return './static/images/' + THEME_ID_DT;
}

//Bank logos path
const getBankLogosPath = () => {
    return './static/bank_logos';
}

export const getBankLogo = (logoName) => {
    console.log("logoName:" + logoName);
    let path;
    try {
        path = require(getBankLogosPath() + '/' + logoName + ".svg");
    }
    catch (e) {
        path = require(getBankLogosPath() + "/default.svg");
    }
    return path;
}

const getImagePath = (imageName) => {
    let path;
    try {
        path = require(getImagesPath() + '/' + imageName + "");
    }
    catch (e) {
        path = require(getDefaultImagesPath() + '/' + imageName + "");
    }
    return path;
}

export const getCaptchaIconPath = () => {
    return getImagePath('captcha_icon.svg');
}

export const getOtpIconPath = () => {
    return getImagePath('otp_icon.svg');
}

export const getQuestionIconPath = () => {
    return getImagePath('question_icon.svg');
}

export const getAccountIconPath = () => {
    return getImagePath('account_icon.svg');
}

export const getBankIconPath = () => {
    return getImagePath('bank_icon.svg');
}

export const getDropDownIconPath = () => {
    return getImagePath('dropdown_icon.svg');
}

export const getLockIconpath = () => {
    return getImagePath('lock_icon.svg');
}

export const getUserNameIconPath = () => {
    return getImagePath('username_icon.svg');
}

export const getAaUpiIdIconPath = () => {
    return getImagePath('username_icon.svg');
}

export const getPasswordIconPath = () => {
    return getImagePath('password_icon.svg');
}

export const getErrorIconPath = () => {
    return getImagePath('error_icon.svg');
}

export const getSuccessIconPath = () => {
    return getImagePath('success_icon.svg');
}

export const getLogoPath = () => {
    return getImagePath('logo.svg');
}

export const getUploadFileIconPath = () => {
    return getImagePath('upload_file.svg');
}

export const getUploadIconPath = () => {
    return getImagePath('ic_upload.svg');
}

export const getBulletIconPath = () => {
    return getImagePath('bullet_icon.svg');
}

export const getPdfIconPath = () => {
    return getImagePath('pdf_icon.svg');
}

export const getCloseIconPath = () => {
    return getImagePath('close.svg');
}

export const getMaintenanceImagePath = () => {
    return getImagePath('maintenance.png');
}

export const getGmailIconPath = () => {
    return getImagePath('gmail_icon.png');
}

export const getFooterLogo = () => {
    return getImagePath('copyright_logo.svg');
}

export const getFileSuccessIcon = () => {
    return getImagePath('file_success_icon.svg');
}

export const getFileErrorIcon = () => {
    return getImagePath('file_error_icon.svg');
}

export const getFileWarningIcon = () => {
    return getImagePath('file_warning_icon.svg');
}

export const getUploadMoreIcon = () => {
    return getImagePath('upload_more.svg');
}

export const getCheckboxCheckedPath = () => {
    return getImagePath('checkbox_checked.svg');
}

export const getCheckboxChecked1Path = () => {
    return getImagePath('checkbox_checked1.svg');
}

export const getCheckboxUnCheckedPath = () => {
    return getImagePath('checkbox_unchecked.svg');
}

export const getSearchIconPath = () => {
    return getImagePath('search_icon.svg');
}

export const getFileDeleteIcon = () => {
    return getImagePath('del_icon.svg');
}

export const retryCount = 3;
export const maxPdfSize = 10485760;  // Max pdf size supports in B
export const minPdfSize = 6;
export const maxAllowedFiles = 10;
export const axisBankInstitutionId = "4";


export const getAnalyticsEnabled = () => {
    return !isNullOrEmpty(localStorage.getItem("analytics_enabled")) ? localStorage.getItem("analytics_enabled") : "0";
}